<template>
  <div class="dropdown" v-bind:class="selected.length ? 'active':false">
    <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
      <span>{{ title }}</span>
    </button>
    <ul class="dropdown-menu">
      <li class="action" v-if="this.multiple">
        <a @click="this.selectAll">Вибрати всі</a>
        <a @click="clear">Очистити</a>
      </li>
      <li class="search"><input type="text" class="form-control" placeholder="Пошук..." v-model="search"></li>
      <li v-for="item in filtered" :key="item" v-bind:class="this.isActive(item) ? 'active':''">
        <a class="dropdown-item" @click="this.toggle(item)">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg"
               viewBox="0 0 16 16">
            <path
                d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
          </svg>
          {{ item.label }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: "Filter",
  props: {
    modelValue: Array,
    multiple: Boolean,
    label: String,
    name: String,
    path: String,
    items: Array
  },
  computed: {
    filtered: function () {
      return this.items.filter(item => !this.search || item.label.toLowerCase().search(this.search.toLowerCase()) !== -1);
    },
    title: function () {

      let title = [];

      for (let select of this.selected) {
        const item = this.items.find(i => i.value === select);
        if (item) title.push(item.label);
      }

      return title.length ? this.label + ': ' + title.join(", ") : this.label;
    }
  },
  watch: {
    modelValue(val) {
      this.selected = val;
    }
  },
  created() {
    const q = this.$route.query;
    this.selected = q[this.name] ? q[this.name].split(',') : [];
  },
  data() {
    return {
      search: null,
      selected: [],
      selectedName: null,
    }
  },
  methods: {
    toggle: function (item) {
      const index = this.selected.findIndex(i => i === item.value);

      if (index >= 0) {
        this.selected.splice(index, 1);
      } else {
        if (!this.multiple)
          this.selected = [];
        this.selected.push(item.value);
      }

      this.routePush(this.selected);
      this.$emit('update:modelValue', this.selected);
    },
    selectAll: function () {
      this.selected = this.filtered.map(i => i.value);
      this.routePush(this.selected);
      this.$emit('update:modelValue', this.selected);
    },
    clear: function () {
      this.selected = [];
      this.routePush(this.selected);
      this.$emit('update:modelValue', []);
    },
    isActive: function (item) {
      return this.selected.findIndex(v => v === item.value) >= 0;
    },
    routePush: function (val) {
      let query = {};

      for (let p of Object.keys(this.$route.query)) {
        if (p === 'page')
          continue;
        query[p] = this.$route.query[p];
      }

      if (val && val.length)
        query[this.name] = val.join(",");
      else
        delete query[this.name];

      this.$router.push({path: this.path, query: query});
    }
  }
}
</script>

<style scoped>

.btn {
  color: #438de2;
  margin-top: 2px;
  padding: 3px 12px;
  border-radius: 8px;
  font-size: 14px;
}

.dropdown-toggle::after {
  font-size: 10px;
}

span.clear {
  margin-left: 10px;
}

.btn.show, .btn:hover, .dropdown.active .btn {
  background-color: #e5f1ff;
}

.btn span {
  display: inline-block;
  float: left;
  text-overflow: ellipsis; /* IE, Safari (WebKit) */
  overflow: hidden; /* don't show excess chars */
  white-space: nowrap; /* force single line */
  max-width: 150px;
}


.dropdown-menu {
  border: 1px solid #e1e1e1;
  box-shadow: 0 3px 10px 0 hsl(0deg 0% 68% / 50%), 0 20px 60px 0 rgb(0 0 0 / 15%);
  color: #333;
  width: 250px;
  margin-top: 6px !important;
}

.dropdown-item {
  color: #333;
  font-size: 13px;
  padding-left: 4px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}

.dropdown-item:focus, .dropdown-item:hover {
  background: #fafafa;
}

.dropdown-item svg {
  opacity: 0;
}

li.active .dropdown-item svg {
  opacity: 1;
}

li.search input {
  border: none;
  font-size: 13px;
  border-radius: 0;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 10px;
  padding: 8px 4px 8px 20px;

}

li.action {
  padding: 0 18px 10px;
}

li.action a {
  font-size: 12px;
  margin-right: 16px;
  text-decoration: none;
  color: #2688cd;
  cursor: pointer;
}

.form-control:focus, input:focus, input:active {
  border: none;
  border-bottom: 1px solid #e1e1e1;
}

::placeholder {
  color: #999;
  font-size: 13px;
  padding-left: 0px;
}

</style>