<template>
  <head-panel>
    <template v-slot:body>
      Проект <span v-if="project"> №{{ project.uuid }}</span></template>
  </head-panel>

  <div class="wrapper">

    <Alert ref="alert"/>

    <form @submit.prevent="submit">

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Назва</div>
        <div class="col-lg-6">
          <input type="text" class="form-control" v-model="form.name" :class="{ 'is-invalid' : v$.form.name.$error}">
          <div class="invalid-feedback" v-for="error of v$.form.name.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div class="row ms-2 mb-4">
        <div class="col-xl-6">
          <button type="submit" class="btn btn-primary">Зберегти</button>
        </div>
      </div>


    </form>
  </div>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import Alert from "../../components/Alert";
import {useVuelidate} from "@vuelidate/core";
import {maxLength, required} from "@vuelidate/validators";

export default {
  name: "Edit",
  components: {
    HeadPanel, Alert
  },
  created() {
    if (!this.$route.params.id)
      return;
    this.fetch(this.$route.params.id);
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        name: {
          required,
          maxLength: maxLength(255),
        }
      }
    };
  },
  data() {
    return {
      project: null,
      form: {
        name: null,
        status: 'ACTIVE'
      }
    }
  },
  methods: {
    fetch: function (uuid) {
      Http
          .get("/project/" + uuid)
          .then((res) => {
            this.project = res.content;
            this.form.name = this.project.name;
          });
    },
    submit: async function () {

      const result = await this.v$.$validate()

      if (!result) return

      if (this.project)
        this.update();
      else
        this.create();
    },
    create: function () {
      Http
          .post("/project", this.form)
          .then((res) => {
            this.fetch(res.content)
            this.$router.replace("/project/" + res.content);
            this.$refs.alert.success('Успішно збережено');
          });
    },
    update: function () {
      Http
          .put("/project/" + this.project.uuid, this.form)
          .then(() => {
            this.$refs.alert.success('Успішно збережено');
          });
    }
  }
};
</script>

<style scoped>

.head-title span {
  font-size: 18px;
}


.invalid-message {
  margin-top: 0.60rem;
  font-size: .875em;
  color: #dc3545;
  float: left;
  margin-left: 10px;
}

textarea.form-control {
  height: 200px;
}

</style>