<template>

  <div class="date-range">
    <el-date-picker
        v-model="model"
        type="daterange"
        unlink-panels
        range-separator="To"
        start-placeholder="Start date"
        end-placeholder="End date"
        :shortcuts="shortcuts"
        @change="change"
    />
  </div>

</template>

<script>

import moment from 'moment'

export default {
  name: "DateRangeFilter",
  components: {},
  props: {
    modelValue: Array,
    path: String,
    updateRoute: Boolean,
    defaultRange: Array
  },
  watch: {
    $route() {
      let from = this.$route.query['from'] ?? null;
      let to = this.$route.query['to'] ?? null;

      if (from && to)
        this.model = [from, to]
      else
        this.model = this.defaultRange;
    },
  },
  data() {
    return {
      model: this.modelValue && this.modelValue.length ? this.modelValue : this.defaultRange,
      shortcuts: [
        {
          text: 'Last week',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            return [start, end]
          },
        },
        {
          text: 'Last month',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            return [start, end]
          },
        },
        {
          text: 'Last 3 months',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            return [start, end]
          },
        },
      ]
    };
  },
  methods: {
    change: function () {
      let date = null;
      if (this.model && this.model.length)
        date = [moment(this.model[0]).format("YYYY-MM-DD"), moment(this.model[1]).format("YYYY-MM-DD")];
      this.routePush(date);
      this.$emit('update:modelValue', date);
    },
    routePush: function (val) {

      if (!this.updateRoute)
        return;

      let query = {};

      for (let p of Object.keys(this.$route.query)) {
        if (p === 'page')
          continue;
        query[p] = this.$route.query[p];
      }

      if (val && val.length) {
        query.from = val[0];
        query.to = val[1];
      } else {
        delete query.from;
        delete query.to;
      }

      this.$router.push({path: this.path, query: query});
    }
  }
}
</script>

<style scoped>
.demo-date-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
}

.demo-date-picker .block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}

.demo-date-picker .block:last-child {
  border-right: none;
}

.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 20px;
}
</style>