import OAuth from "./OAuth2";

const oauth = new OAuth(process.env.VUE_APP_OAUTH_URL, process.env.VUE_APP_OAUTH_CLIENT_ID, process.env.VUE_APP_OAUTH_REDIRECT_URL);

const METHOD_GET = 'GET';
const METHOD_POST = 'POST';
const METHOD_PUT = 'PUT';
const METHOD_DELETE = 'DELETE';

const session = {
    project: null,
    available: [],
    permissions: [],
    integrations: {},
};

export default {
    get,
    post,
    put,
    del,
    request,
    getQueryParameter,
    session
};

function get(url) {
    return this.request(METHOD_GET, url)
}

function post(url, body) {
    return this.request(METHOD_POST, url, body)
}

function put(url, body) {
    return this.request(METHOD_PUT, url, body)
}

function del(url) {
    return this.request(METHOD_DELETE, url)
}

function requestBody(method, body) {

    let headers = {}

    if (session.project)
        headers['X-Project-Identity'] = session.project.uuid;

    let requestOptions;

    if (body instanceof FormData) {
        requestOptions = {
            method: method,
            headers: headers,
            body: body
        };
    } else {
        headers['Content-Type'] = 'application/json';
        requestOptions = {
            method: method,
            headers: headers,
            body: JSON.stringify(body)
        };
    }

    if (oauth.getToken() != null)
        requestOptions.headers.Authorization = 'Bearer ' + oauth.getToken().accessToken;

    return requestOptions;
}


function request(method, url, body) {

    let requestOptions = requestBody(method, body);

    function repeat(method, url, body) {
        let requestOptions = requestBody(method, body);
        return fetch(url, requestOptions);
    }

    async function onResponse(response) {

        if (response.ok) {
            const json = await response.json();
            json.origin = response;
            return json;
        }

        if (response.status === 403 && oauth.isUMA(response)) {
            oauth.requestRPT(response);
            return (await repeat(method, url, body)).json();
        }

        if (response.status === 401) {
            if (oauth.getToken()) {
                oauth.refreshToken();
                return (await repeat(method, url, body)).json();
            } else {
                oauth.auth();
            }
        }

        if (response.status === 400) {
            const json = await response.json();
            json.origin = response;
            return new Promise((resolve, reject) => {
                reject(json);
            });
        }
        throw Error(response);
    }

    return fetch(url, requestOptions)
        .then(onResponse)
        .catch((res) => {
            return new Promise((resolve, reject) => {
                reject(res);
            });
        });
}

function getQueryParameter(name) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    return typeof params[name] !== 'undefined' ? params[name] : null;
}