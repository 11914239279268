<template>
  <div class="head-title">
    <div class="head-block-title">
    <slot name="body">
      Home
    </slot>
    </div>
    <head-panel-project/>
  </div>
</template>

<script>

import HeadPanelProject from "./HeadPanelProject.vue";

export default {
  name: "HeadPanel",
  components: {
    HeadPanelProject
  }
}
</script>

<style scoped>
@import '../assets/css/header.css';
</style>