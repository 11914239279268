import pkceChallenge from "pkce-challenge";


class Token {
    constructor(accessToken, refreshToken) {
        this.accessToken = accessToken;
        this.refreshToken = refreshToken;
    }
}


export default class OAuth {

    constructor(authUrl, clientId, redirectUrl) {
        this.authUrl = authUrl;
        this.clientId = clientId;
        this.redirectUrl = redirectUrl;
        this.method = "S256";
        this.tokenPropertyName = 'cree.token';
    }

    logout() {
        this.clearToken();
        location.href = this.authUrl + '/logout?redirect_uri=' + this.redirectUrl;
    }

    auth() {
        this.clearToken();
        const challenge = pkceChallenge(128);
        let query = {
            client_id: this.clientId,
            redirect_uri: this.redirectUrl,
            response_type: "code",
            code_challenge_method: this.method,
            code_challenge: challenge.code_challenge,
            state: this.randomString(11)
        };
        sessionStorage.setItem("verifier", challenge.code_verifier);
        location.href = this.authUrl + "/auth?" + new URLSearchParams(query).toString();
    }

    requestToken() {
        let xhr = new XMLHttpRequest();
        xhr.open("POST", this.authUrl + "/token", false);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

        const codeVerifier = this.getVerifier(true);

        let data = {
            grant_type: "authorization_code",
            client_id: this.clientId,
            redirect_uri: this.redirectUrl,
            code: this.getQueryParameter('code'),
            code_verifier: codeVerifier,
        };

        xhr.send(new URLSearchParams(data).toString());
        if (xhr.status === 200) {
            const data = JSON.parse(xhr.responseText);
            this.storeToken(new Token(data.access_token, data.refresh_token));
        }
    }

    refreshToken() {

        const token = this.getToken();

        if (!token)
            return this.auth();

        let xhr = new XMLHttpRequest();
        xhr.open("POST", this.authUrl + "/token", false);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

        this.clearVerifier();

        let data = {
            grant_type: "refresh_token",
            client_id: this.clientId,
            redirect_uri: this.redirectUrl,
            refresh_token: token.refreshToken,
        };

        xhr.send(new URLSearchParams(data).toString());

        if (xhr.status === 200) {
            const data = JSON.parse(xhr.responseText);
            this.storeToken(new Token(data.access_token, data.refresh_token));
        } else {
            return this.auth();
        }
    }

    getVerifier(clear) {
        const codeVerifier = sessionStorage.getItem("verifier");

        if (clear)
            this.clearVerifier();

        return codeVerifier;
    }

    clearVerifier() {
        sessionStorage.removeItem("verifier");
    }

    storeToken(token) {
        window.sessionStorage.setItem(this.tokenPropertyName, JSON.stringify(token));
    }

    clearToken() {
        window.sessionStorage.removeItem(this.tokenPropertyName);
    }

    getToken() {
        const json = window.sessionStorage.getItem(this.tokenPropertyName);

        if (typeof json == 'undefined' || json === 'undefined' || !json)
            return null;

        const data = JSON.parse(json);

        return new Token(data.accessToken, data.refreshToken);
    }


    randomString(length) {

        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;

    }

    getQueryParameter(name) {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        return typeof params[name] !== 'undefined' ? params[name] : null;
    }

}


