class Overlay {

    constructor() {
        this.events = {};
    }

    addEvent(name, f){
        this.events[name] = f;
    }

    removeEvent(name){
        delete this.events[name]
    }

    state(state, cls) {
        if (state)
            this.show(cls)
        else
            this.hide(cls)
    }

    show(cls) {

        if(cls)
            document.body.classList.add(cls);
        else
            document.body.classList= [];

        document.body.classList.add('isOverflow');
        document.getElementById("overlay").addEventListener('click', this.emit);
        window.addEventListener('keyup', this.emit);
    }

    hide(cls) {

        if(cls)
            document.body.classList.remove(cls);

        document.body.classList.remove('isOverflow');
        document.getElementById("overlay").removeEventListener('click', this.emit);
        window.removeEventListener('keyup', this.emit);
    }

    emit(e) {
        for (const f of Object.entries(overlay.events))
             f[1](e);
    }
}

const overlay = new Overlay();

export default overlay;