<template>
  <head-panel>
    <template v-slot:body>Проекти
      <router-link to="/project/create" class="btn btn-primary btn-sm btn-create">Create</router-link>
    </template>
  </head-panel>

  <div class="wrapper">

    <div class="filter">
      <div class="item">
        <SearchFilter
            label="Швидкий пошук"
            name="search"
            path="project"
        />
      </div>
    </div>


    <table class="table">
      <thead>
      <tr>
        <th scope="col">№</th>
        <th scope="col">Імя</th>
        <th scope="col">Sandbox</th>
        <th scope="col">Створений</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <template v-for="item in items" :key="item">
        <tr>
          <td>{{ item.uuid }}</td>
          <td>{{ item.name }}</td>
          <td>
            <div class="form-check form-switch">
              <input class="form-check-input cursor-pointer" type="checkbox" v-bind:checked="item.sandbox"
                     @change="sandbox(item)">
            </div>
          </td>
          <td>{{ $filters.dateTime(item.createdAt) }}</td>
          <td class="actions">
            <router-link :to="'/project/'+item.uuid">Ред.</router-link>
            <a @click="remove(item)">Видалити</a>
          </td>
        </tr>
      </template>

      </tbody>
    </table>

    <Pagination
        :current-page="pagination.page"
        :total-pages="pagination.total"/>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import Pagination from "../../components/Pagination";
import SearchFilter from "../../components/SearchFilter";
import ConfirmDialogue from "../../components/ConfirmDialog";

export default {
  name: "Index",
  components: {
    HeadPanel, Pagination, SearchFilter, ConfirmDialogue
  },
  watch: {
    $route(n, o) {
      if (n.path === o.path)
        this.fetch();
    }
  },
  created() {
    this.fetch();
  },
  data() {
    return {
      items: [],
      pagination: {
        page: 0,
        total: 0
      },
    }
  },
  methods: {
    fetch: function () {
      Http
          .get(this.$route.fullPath)
          .then((res) => {
            this.items = res.content;
            this.pagination.page = res.origin.headers.get("X-Current-Page") * 1;
            this.pagination.total = res.origin.headers.get("X-Page-Count") * 1;
          });
    },
    remove: function (item) {

      this.$refs.confirmDialogue.show({
        title: 'Видалення',
        message: 'Ви дійсно хочете видалити проект?',
        okButton: 'Так',
        cancelButton: 'Ні',
      }).then((ok) => {
        if (ok)
          Http
              .del("/project/" + item.uuid)
              .then(() => this.fetch());
      })
    },

    sandbox: function (item) {
      this.$refs.confirmDialogue.show({
        title: 'Режим пісочниці',
        message: item.sandbox ?
            'Ви переводите додаток в режим реального використання. Всі повідомлення будуть надсилатись і тарифікуватись відповідно вашого тарифу' :
            'Ви переводите додаток в режим пісочниці(Тестове середовище). Повідомлення реально не будуть надіслані кінцевим користувачам',
        okButton: 'Ok',
        cancelButton: 'Відміна',
      }).then((ok) => {
        if (ok)
          Http
              .put("/project/" + item.uuid + "/sandbox?state=" + (!item.sandbox))
              .then(() => this.fetch());
        else
          this.fetch();
      })
    }
  }
};
</script>

<style scoped>
.date-range {
  float: right;
}

.filter {
  text-align: center;
  margin: 0 0 20px 20px;
  display: flex;
}

.search {
  position: relative;
}

.search input {
  padding: 0.275rem 0.75rem 0.275rem 40px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.11);
}

::placeholder {
  color: #999;
}

.search svg {
  margin-right: 12px;
  width: 22px;
  height: 22px;
  display: inline-block;
  flex-shrink: 0;
  user-select: none;
  color: #0000008a;
  float: left;
  position: absolute;
  left: 10px;
  top: 5px;
}

.search svg path {
  color: #999;
}

table td {
  font-size: 13px;
}

.item {
  margin-right: 20px;
}

.wrapper {
  height: 100%;
}

</style>