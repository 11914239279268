import moment from "moment";

export default function config(app) {


    app.config.globalProperties.$filters = {

        hour(value, format) {

            if (!format)
                format = 'HH';

            return moment(String(value)).format(format);
        },

        minute(value, format) {

            if (!format)
                format = 'mm';

            return moment(String(value)).format(format);
        },

        date(value, format) {

            if (!format)
                format = 'YYYY-MM-DD';

            return moment(String(value)).format(format);
        },

        dateTime(value) {
            return moment(String(value)).format('YYYY-MM-DD HH:mm:ss');
        },


        status(status) {
            const labels = {
                active: 'Активний',
                disabled: 'Неактивний',
                low: 'В черзі',
                high: 'В черзі',
                complete: 'Відправлений',
                fail: 'Помилка',
            };
            return labels[status.toLowerCase()];
        }
    }
}