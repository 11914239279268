<template>
  <head-panel>
    <template v-slot:body>Distributions
    </template>
  </head-panel>

  <div class="wrapper  ">


    <nav class="sub-nav">
      <ul>
        <li>
          <router-link :to="'/distribution/mail'"
                       v-bind:class="this.$route.path === '/distribution/mail' ? 'active':''">Mail
          </router-link>
        </li>
        <li>
          <router-link :to="'/distribution/sms'" v-bind:class="this.$route.path === '/distribution/sms' ? 'active':''">
            Sms
          </router-link>
        </li>
        <li>
          <router-link :to="'/distribution/telegram'"
                       v-bind:class="this.$route.path === '/distribution/telegram' ? 'active':''">
            Telegram
          </router-link>
        </li>
      </ul>
    </nav>

    <div class="filter float-end" v-if="items.length">
      <div class="item">
        <DateRangeFilter v-model="dateRange" :update-route="true"
                         :path="'/distribution/' + $route.params.type"/>
      </div>
    </div>
    <table class="table" v-if="items.length">
      <thead>
      <tr>
        <th scope="col">№</th>
        <th scope="col">Recipient</th>
        <th scope="col">Message</th>
        <th scope="col">Status</th>
        <th scope="col">Created</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <template v-for="item in items" :key="item">
        <tr>
          <td>{{ item.id }}</td>
          <td>{{ item.recipient }}</td>
          <td>{{ item.name }}</td>
          <td>
            <span class="status">{{ statuses[item.status] }}</span>
          </td>
          <td>{{ $filters.dateTime(item.date) }}</td>
          <td class="actions">
            <a @click="show(item)" v-if="!detail || detail.id !== item.id">Detail</a>
            <a @click="hide()" v-else>Hide</a>
          </td>
        </tr>

        <tr v-if="detail && detail.id === item.id">
          <td colspan="6" class="detail">

            <div class="card">

              <div class="card-body">
                <h5 class="card-title">ID</h5>
                <p class="card-text">{{ detail.id }}</p>
                <h5 class="card-title">Recipient</h5>
                <p class="card-text">{{ detail.recipient }}</p>
                <h5 class="card-title">Sandbox</h5>
                <p class="card-text">{{ detail.sandbox }}</p>
                <h5 class="card-title">Name</h5>
                <p class="card-text">{{ detail.name }}</p>
                <h5 class="card-title">Body</h5>
                <p class="card-text">{{ detail.body }}</p>
                <h5 class="card-title">Tokens</h5>
                <p class="card-text">{{ detail.tokens }}</p>
                <h5 class="card-title">Meta</h5>
                <p class="card-text">{{ detail.meta }}</p>
                <h5 class="card-title">Response</h5>
                <p class="card-text">{{ detail.response }}</p>
              </div>
            </div>

          </td>
        </tr>

      </template>

      </tbody>
    </table>
    <Pagination type="simple" :current-page="pagination.page" :items-length="items.length" :page-size="50" v-if="items.length"/>


    <div class="ms-4" v-if="!items.length">
      <br/>
      <br/>
      <br/>
      No messages send yet
    </div>
  </div>

</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import Pagination from "../../components/Pagination";
import DateRangeFilter from "../../components/DateRangeFilter";
import moment from "moment";

export default {
  name: "Distributions",
  components: {
    HeadPanel, DateRangeFilter, Pagination
  },
  watch: {
    $route() {
      if (this.$route.params.type)
        this.fetch();

      // if (this.$route.query.page && this.$route.query.page > 1)
      //   this.pagination.page = this.$route.query.page - 1;
    },
    dateRange() {
      this.fetch();
    }
  },
  created() {
    this.fetch();
  },
  computed: {
    statuses() {
      return {'0': 'NEW', '1': 'NO_HANDLE', '2': 'SKIP', '3': 'PARTIAL_SUCCESS', '4': 'COMPLETE', '5': 'FAIL'};
    },
  },
  data() {
    return {
      dateRange: [moment().subtract(1, 'month').toDate(), moment().toDate()],
      detail: null,
      items: [],
      pagination: {
        page: 0
      },
    }
  },
  methods: {
    fetch: function () {
      const from = moment(this.dateRange[0]).format("YYYY-MM-DD");
      const to = moment(this.dateRange[1]).format("YYYY-MM-DD");

      this.pagination.page = this.$route.query.page ? this.$route.query.page * 1 : 0;

      Http
          .get("/delivery/analytics/distribution?type=" + this.$route.params.type + "&from=" + from + "&to=" + to + "&page=" + this.pagination.page)
          .then((res) => {
            this.items = res.content;
          });
    },
    show: function (item) {
      Http
          .get("/delivery/analytics/distribution/" + item.aid + "." + item.tid + "." + item.id)
          .then((res) => {
            this.detail = res.content;
          });
    },
    hide: function () {
      this.detail = null;
    }
  }
};
</script>

<style scoped>

table td {
  font-size: 13px;
}

.item {
  margin-right: 20px;
}


</style>