<template>
    <div class="modal" >
      <header class="modal-header">
        <slot name="header">
        </slot>
        <button
            type="button"
            class="btn-close"
            @click="close"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg"
               viewBox="0 0 16 16">
            <path
                d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
          </svg>
        </button>
      </header>

      <section class="modal-body">
        <slot name="body">
          This is the default body!
        </slot>
      </section>

      <footer class="modal-footer">
        <slot name="footer">
        </slot>
      </footer>
    </div>
</template>

<script>
import Overlay from "@/lib/Overlay";

export default {
  name: "Modal",
  beforeMount() {
    window.addEventListener('keyup', this.keyEvent);
    Overlay.addEvent("modalShadowEvent", this.clickEvent);
    Overlay.state(true);
  },
  beforeUnmount () {
    window.removeEventListener('keyup', this.keyEvent)
    Overlay.removeEvent("modalShadowEvent");
    Overlay.state(false);
  },
  methods: {
    clickEvent(e){
      if(e.type !== 'click')
        return;
      this.close();
    },
    keyEvent(e) {
      if (e.type !== 'keyup' || e.key !== "Escape")
        return;
      this.close();
    },
    close() {
      this.$emit('close');
    },
  },
}
</script>

<style scoped>


.modal {
  background: #FFFFFF;
  /* box-shadow: 2px 2px 20px 1px; */
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  width: 600px;
  height: auto;
  border-radius: 6px;
  position: fixed;
  margin: 10% auto;
  left: 0;
  right: 0;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  font-size: 28px;
  position: relative;
  border-bottom: 0 solid #eeeeee;
  color: #000;
  justify-content: space-between;
  min-height: 50px;
}

.modal-footer {
  border-top: 0 solid #eeeeee;
}

.modal-footer:empty {
  display: none;
}

.modal-body {
  position: relative;
  padding: 0px 10px;
}

.modal-body .empty {
  text-align: center;
  opacity: 0.4;
}

.modal-body .empty div {
  font-family: Roboto, sans-serif;
  color: #000000;
  font-size: 30px;
  line-height: 36px;
  font-weight: 400;
}

.modal-header .btn-close {
  position: absolute;
  top: 15px;
  right: 30px;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #0000008a;
  background: transparent;
  margin: -0.5rem -1rem -0.5rem auto;
}

.btn-close svg {
  width: 20px;
  height: 20px;
}

@media (max-width: 992px) {
  .modal {
    width: 96%;
  }
}

@media (max-width: 768px) {
  .modal {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  .modal-footer{
    display: block;
  }
  .additional-block{
    display: none;
  }
}

</style>