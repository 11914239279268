<template>

  <modal class="short-modal" @close="close">

    <template v-slot:header>
      Choose project
    </template>
    <template v-slot:body>

      <table class="table" v-if="items.length">
        <thead>
        <tr>
          <th scope="col">Імя</th>
        </tr>
        </thead>
        <tbody>
        <template v-for="item in items" :key="item">
          <tr @click="choose(item)" class="cursor-pointer">
            <td>{{ item.name }}</td>
          </tr>
        </template>

        </tbody>
      </table>

      <div v-if="!items.length && !showCreateForm">You haven't any projects</div>

      <form @submit.prevent="submit" v-if="showCreateForm">

        <div>
          <label for="exampleInputEmail1" class="form-label">Name</label>
          <input type="text" class="form-control" v-model="form.name" :class="{ 'is-invalid' : v$.form.name.$error}">
          <div class="invalid-feedback" v-for="error of v$.form.name.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>


      </form>


    </template>
    <template v-slot:footer>
      <button class="btn btn-primary" v-if="!items.length && !showCreateForm" @click="showCreateForm = true">Create new
        one
      </button>
      <button class="btn btn-primary" v-if="showCreateForm" @click="submit">Create new project</button>
    </template>
  </modal>
</template>

<script>
import Modal from "../../components/Modal.vue";
import Http from "../../lib/Http";
import {useVuelidate} from "@vuelidate/core";
import {maxLength, required} from "@vuelidate/validators";

export default {
  name: "ProjectChoose",
  components: {Modal},
  created() {
    this.fetch();
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        name: {
          required,
          maxLength: maxLength(255),
        }
      }
    };
  },
  data() {
    return {
      showCreateForm: false,
      items: [],
      pagination: {
        page: 0,
        total: 0
      },
      form: {
        name: null,
        status: 'ACTIVE'
      }
    }
  },
  methods: {
    fetch: function () {
      Http
          .get("/project")
          .then((res) => {
            this.items = res.content;
            this.pagination.page = res.origin.headers.get("X-Current-Page") * 1;
            this.pagination.total = res.origin.headers.get("X-Page-Count") * 1;
          });
    },
    choose(item) {
      this.$cookies.set('project', item.uuid);
      this.$emit('close');
      document.location.reload();
    },
    submit: async function () {

      const result = await this.v$.$validate()

      if (!result) return

      Http
          .post("/project", this.form)
          .then((res) => {
            this.$cookies.set('project', res.content);
            this.$emit('close');
            document.location.reload();
          })
          .catch(() => {
            this.$refs.alert.error('Помилка при створені проекту');
          });
    },
    close() {
      this.$emit('close');
    },
  }
}
</script>

<style scoped>

</style>