<template>
  <head-panel>
    <template v-slot:body>Dashboard</template>
  </head-panel>

  <div class="ps-4 pe-4 mt-4">

    <div class="stats-wrapper mb-3">

      <div class="row">
        <div class="col">

        </div>
        <div class="col-auto ">
          <DateRangeFilter v-model="dateRange" path="/"/>
        </div>
      </div>


      <div style="height: 400px;">
        <v-chart class="chart" :option="chart"/>
      </div>
    </div>

  </div>
</template>

<script>
import DateRangeFilter from "../../components/DateRangeFilter";
import moment from 'moment'
import HeadPanel from "../../components/HeadPanel";
import Http from "../../lib/Http";

export default {
  name: "Index",
  components: {
    DateRangeFilter,
    HeadPanel
  },
  created() {
    this.fetch();
  },
  watch: {
    dateRange() {
      this.fetch();
    }
  },
  data() {
    return {
      counter: null,
      dateRange: [moment().subtract(1, 'month').toDate(), moment().toDate()],
      chart: null
    }
  },
  methods: {
    fetch: function () {
      const from = moment(this.dateRange[0]).format("YYYY-MM-DD");
      const to = moment(this.dateRange[1]).format("YYYY-MM-DD");
      Http.get('/delivery/analytics/aggregate?from=' + from + '&to=' + to)
          .then(res => {
            this.chart = this._chartData(res.content);
          });
    },
    _chartData: function (data) {

      const dates = {};
      const series = {};

      for (let item of data) {
        dates[item.date] = 1;

        if (!series['sms'])
          series['sms'] = {};

        if (!series['mail'])
          series['mail'] = {};

        if (!series['telegram'])
          series['telegram'] = {};


        if (!series['sms'][item.date])
          series['sms'][item.date] = 0;

        if (!series['mail'][item.date])
          series['mail'][item.date] = 0;

        if (!series['telegram'][item.date])
          series['telegram'][item.date] = 0;

        series[item.type][item.date] = item.count;

      }

      const xAxisData = Object.keys(dates).sort();

      const seriesGroup = {};
      seriesGroup['sms'] = [];
      seriesGroup['mail'] = [];
      seriesGroup['telegram'] = [];

      for (let x of xAxisData) {
        seriesGroup['sms'].push(series['sms'][x]);
        seriesGroup['mail'].push(series['mail'][x]);
        seriesGroup['telegram'].push(series['telegram'][x]);
      }


      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '0%',
          right: '0%',
          bottom: '0%',
          // width: '100%',
          containLabel: true
        },
        xAxis: [
          {
            // interval: 100,
            // min: 5,
            // max: 10,
            type: 'category',
            boundaryGap: false,
            data: xAxisData
          }
        ],
        yAxis: [
          {
            type: 'value',
            position: 'right'
            // splitLine: {
            //   show: false
            // }
          }
        ],
        series: [
          {
            name: 'Mail',
            type: 'line',
            symbolSize: 8,
            lineStyle: {
              color: '#f0ad4e',
            },
            itemStyle: {
              color: '#f0ad4e',//'#f5803e',
            },
            data: seriesGroup['mail']
          },
          {
            name: 'Sms',
            type: 'line',
            symbolSize: 8,
            lineStyle: {
              color: '#16c76b',
            },
            itemStyle: {
              color: '#16c76b',//'#f5803e',
            },
            data: seriesGroup['sms']
          },
          {
            name: 'Telegram',
            type: 'line',
            symbolSize: 8,
            lineStyle: {
              color: '#248afd',
            },
            itemStyle: {
              color: '#248afd',//'#f5803e',
            },
            data: seriesGroup['telegram']
          },
        ]
      };
    }
  }
};
</script>

<style scoped>
</style>