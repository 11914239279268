import {use} from "echarts/core"
import {CanvasRenderer} from "echarts/renderers";
import {BarChart, LineChart} from "echarts/charts";
import {TooltipComponent, LegendComponent, GridComponent} from "echarts/components";
import ECharts from "vue-echarts";

use([
    CanvasRenderer,
    BarChart,
    LineChart,
    GridComponent,
    TooltipComponent,
    LegendComponent
]);

export default function config(app){
    app.component('v-chart', ECharts);
}