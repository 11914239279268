<template>

  <div class="head-block cursor-pointer" @click="projectModalShow = true" v-if="project">
    <div class="head-block-line head-block-line-first">{{ project.name }}</div>
    <div class="head-block-line head-block-line-second">ID: {{ project.uuid }}</div>
  </div>

  <project-choose-modal v-if="projectModalShow" @close="close"/>

</template>

<script>
import ProjectChooseModal from "../views/project/ProjectChooseModal.vue";

export default {
  name: "ProjectChoose",
  components: {ProjectChooseModal},
  created() {
    this.fetch();
  },
  data() {
    return {
      projectModalShow: false,
      project: null
    }
  },
  methods: {
    fetch: function () {
      this.project = this.$session.project;
      if (!this.project)
        this.projectModalShow = true;
    },

    close: function () {
      this.projectModalShow = false;
    },
  }
}
</script>

<style scoped>

</style>