import {createApp} from 'vue'
import App from './App.vue'
import Router from './router/Index'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import Http from "./lib/Http";
import VueCookies from 'vue-cookies'
import configFormatter from './config/Formatter'
import configCharts from './config/Charts'
import OAuth from './lib/OAuth2';


if (location.pathname === "/" && Http.getQueryParameter('code')) {
    const oauth = new OAuth(process.env.VUE_APP_OAUTH_URL, process.env.VUE_APP_OAUTH_CLIENT_ID, process.env.VUE_APP_OAUTH_REDIRECT_URL);
    oauth.requestToken();
    location.href = "/";
} else {

    let uuid = getCookie("project");

    if (uuid) {
        Http.session.project = {
            uuid: uuid
        }
    }


    Http
        .get("/session")
        .then(res => {
            const app = createApp(App);

            app.config.globalProperties.$session = res.content;
            app.config.globalProperties.$session.can = function (...permissions) {
                if (!this.permissions || !this.permissions.length)
                    return false;

                for (let permission of permissions)
                    if (this.permissions.includes(permission))
                        return true;

                return false;
            }


            app.use(ElementPlus)
            app.use(VueCookies, {expire: '7d'})

            app.use(Router).mount('#app')
            configFormatter(app);
            configCharts(app);
        });
}

function getCookie(cookieName) {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(cookieName + '=')) {
            return cookie.substring(cookieName.length + 1);
        }
    }

    return null;
}
