<template>
  <head-panel>
    <template v-slot:body>
      Аккаунт <span v-if="account"> №{{ account.uuid }}</span>
    </template>
  </head-panel>

  <div class="wrapper" v-if="account">

    <Alert ref="alert"/>

    <form @submit="submit">

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Client ID</div>
        <div class="col-lg-6">
          <input type="text" class="form-control" v-model="account.uuid" disabled>
        </div>
      </div>

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Client Secret</div>
        <div class="col-lg-6">
          <input type="text" class="form-control" v-model="form.secret">
        </div>
      </div>

      <div class="row ms-2 mb-4">
        <div class="col-xl-6">
          <button type="submit" class="btn btn-primary">Save</button>
        </div>
      </div>


    </form>
  </div>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import Alert from "../../components/Alert"

export default {
  name: "Edit",
  components: {
    HeadPanel, Alert
  },
  computed: {
    statuses() {
      return [
        {'name': 'active', 'label': 'Активний'},
        {'name': 'disabled', 'label': 'Неактивний'}
      ];
    },
  },
  created() {
    this.fetch();
  },
  data() {
    return {
      account: null,
      form: {
        secret: null
      }
    }
  },
  methods: {
    fetch: function () {
      Http
          .get("/account/" + this.$route.params.id)
          .then((res) => {
            this.account = res.content;
            this.form.secret = this.account.secret;
            this.$refs.alert.success('Успішно збережено');
          });
    },
    update: function () {
      Http
          .put("/account/" + this.account.uuid, this.form)
          .then(() => {
            this.fetch();
            this.$refs.alert.success('Успішно збережено');
          });
    },
    submit: function (e) {
      e.preventDefault();
      this.update();
    }
  }
};
</script>

<style scoped>

.head-title span {
  font-size: 18px;
}

.invalid-message {
  margin-top: 0.60rem;
  font-size: .875em;
  color: #dc3545;
  float: left;
  margin-left: 10px;
}

textarea.form-control {
  height: 200px;
}

</style>