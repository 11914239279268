import { createRouter, createWebHashHistory} from "vue-router";
import Sidebar from '../components/Sidebar.vue'
import Index from "../views/main/Index"
import AccountList from "../views/account/Index"
import AccountEdit from "../views/account/Edit"
import ProjectList from "../views/project/Index"
import ProjectEdit from "../views/project/Edit"
import ChannelList from "../views/channel/Index"
import ChannelEdit from "../views/channel/Edit"
import MessageList from "../views/message/Index"
import MessageEdit from "../views/message/Edit"
import TelegramConfigList from "../views/telegram/ConfigIndex.vue"
import DistributionList from "../views/distribution/Index"

const routes = [
    {
        path: "/",
        name: "Index",
        components: {
            default: Index,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/account",
        name: "Accounts",
        components: {
            default: AccountList,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/account/:id",
        name: "AccountEdit",
        components: {
            default: AccountEdit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/project",
        name: "Projects",
        components: {
            default: ProjectList,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/project/create",
        name: "ProjectCreate",
        components: {
            default: ProjectEdit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/project/:id",
        name: "ProjectEdit",
        components: {
            default: ProjectEdit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/channel",
        name: "Channels",
        components: {
            default: ChannelList,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/channel/create",
        name: "ChannelCreate",
        components: {
            default: ChannelEdit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/channel/:id",
        name: "ChannelEdit",
        components: {
            default: ChannelEdit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/message",
        name: "Message",
        components: {
            default: MessageList,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/message/create",
        name: "MessageCreate",
        components: {
            default: MessageEdit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/message/:id",
        name: "MessageEdit",
        components: {
            default: MessageEdit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/telegram/config",
        name: "TelegramConfig",
        components: {
            default: TelegramConfigList,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/distribution/:type",
        name: "DistributionList",
        components: {
            default: DistributionList,
            LeftSidebar: Sidebar
        }
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router